<template>
    <div class="adminContainer my-5">
        <div class="row">

            <div class="col-12 col-sm-3 col-md-2">
                <b-navbar toggleable="sm" type="light" variant="light" >
                    <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
                    <b-collapse id="nav-text-collapse" is-nav>
                        <b-navbar-nav class="flex-column">
                            <b-nav-item :to="{name: 'AdminAccount'}" >Moje konto</b-nav-item>
                            <b-nav-item :to="{name: 'AdminCalendar'}">Kalendarz</b-nav-item>
                            <b-nav-item :to="{name: 'AdminDeliveries'}">Dostawy</b-nav-item>
                            <b-nav-item :to="{name: 'AdminOrders'}">Zamówienia</b-nav-item>
                            <b-nav-item :to="{name: 'AdminPayments'}">Płatności</b-nav-item>
                            <b-nav-item :to="{name: 'AdminBaskets'}">Koszyki</b-nav-item>
                            <hr />
                            <b-nav-item :to="{name: 'AdminUsers'}">Użytkownicy</b-nav-item>
                            <b-nav-item :to="{name: 'AdminCities'}">Miasta</b-nav-item>
                            <b-nav-item :to="{name: 'AdminCitiesAsked'}">Miasta nowe</b-nav-item>
                            <b-nav-item :to="{name: 'AdminAddresses'}">Adresy</b-nav-item>
                            <b-nav-item :to="{name: 'AdminRoutes'}">Trasy</b-nav-item>
                            <b-nav-item :to="{name: 'AdminProducts'}">Produkty</b-nav-item>
                            <b-nav-item :to="{name: 'AdminCosts'}">Koszty</b-nav-item>
                            <b-nav-item :to="{name: 'AdminHolidays'}">Święta</b-nav-item>
                        </b-navbar-nav>
                    </b-collapse>
                </b-navbar>
            </div>
            <div class="col-12 col-sm-9 col-md-10">
                <router-view v-if="$store.state.auth.me"></router-view>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'adminContainer'
}

</script>